// extracted by mini-css-extract-plugin
export var bgGrey100 = "TextAndLeftImage-module--bg-grey-100--ccad1";
export var bgGrey150 = "TextAndLeftImage-module--bg-grey-150--1a9e4";
export var bgGrey200 = "TextAndLeftImage-module--bg-grey-200--07127";
export var bgGrey300 = "TextAndLeftImage-module--bg-grey-300--e794d";
export var bgGrey400 = "TextAndLeftImage-module--bg-grey-400--d5ded";
export var bgGrey500 = "TextAndLeftImage-module--bg-grey-500--22749";
export var bgGrey600 = "TextAndLeftImage-module--bg-grey-600--48cf4";
export var bgGrey700 = "TextAndLeftImage-module--bg-grey-700--4cd6c";
export var bgGrey800 = "TextAndLeftImage-module--bg-grey-800--61063";
export var bgGrey900 = "TextAndLeftImage-module--bg-grey-900--b9670";
export var buttonsCls = "TextAndLeftImage-module--buttonsCls--c8fc2";
export var headingCls = "TextAndLeftImage-module--headingCls--8f781";
export var imageCls = "TextAndLeftImage-module--imageCls--90b04";
export var linkBtnStyles = "TextAndLeftImage-module--linkBtnStyles--eaa60";
export var textAreaCls = "TextAndLeftImage-module--textAreaCls--52092";
export var textAreaWrapperCls = "TextAndLeftImage-module--textAreaWrapperCls--d5a31";
export var textGrey100 = "TextAndLeftImage-module--text-grey-100--8bdc7";
export var textGrey150 = "TextAndLeftImage-module--text-grey-150--28f50";
export var textGrey200 = "TextAndLeftImage-module--text-grey-200--2c007";
export var textGrey300 = "TextAndLeftImage-module--text-grey-300--1ff59";
export var textGrey400 = "TextAndLeftImage-module--text-grey-400--1330e";
export var textGrey500 = "TextAndLeftImage-module--text-grey-500--6d7c8";
export var textGrey600 = "TextAndLeftImage-module--text-grey-600--f456b";
export var textGrey700 = "TextAndLeftImage-module--text-grey-700--f5914";
export var textGrey800 = "TextAndLeftImage-module--text-grey-800--8c036";
export var textGrey900 = "TextAndLeftImage-module--text-grey-900--11ee7";