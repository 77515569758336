import { graphql } from "gatsby"
import React from "react"

import TextAndLeftImageComponent from "./TextAndLeftImage"

export const fragment = graphql`
  fragment TextAndLeftImageFragment on WpPage_Flexlayouts_FlexibleLayouts_TextAndLeftImage {
    sectionHeading
    text
    buttonsGroup {
      first {
        url
        title
        target
      }
      second {
        url
        title
        target
      }
    }
    image {
      altText
      sourceUrl
      localFile {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            width: 660
            placeholder: NONE
          )
        }
      }
    }
  }
`

const ACFTextAndLeftImage = ({
  sectionHeading,
  text,
  image,
  buttonsGroup: buttons,
}) => (
  <TextAndLeftImageComponent
    sectionHeading={sectionHeading}
    image={image}
    text={text}
    buttons={buttons}
  />
)

export default ACFTextAndLeftImage
