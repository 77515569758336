import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Row, Col } from "react-bootstrap"

import { Section, Container, TextBlock, LinkButton } from "../../UI/Common"

import {
  imageCls,
  textAreaWrapperCls,
  textAreaCls,
  headingCls,
  buttonsCls,
  linkBtnStyles
} from "./TextAndLeftImage.module.scss"

const TextAndLeftImage = ({ sectionHeading, image, text, buttons }) => {
  const { first: firstBtn, second: secondBtn } = buttons


  return (
    <Section className="position-relative">
        <Container mode="wide">
          <Row>
            <Col xs={12} xl={5} className="pt-3">
              <div>
                {image && (
                  <GatsbyImage
                    className={imageCls}
                    alt={image.altText}
                    objectFit="scale-down"
                    image={getImage(image.localFile)}
                  />
                )}
                </div>
            </Col>

            <Col xs={12} xl={{ span: 6, offset: 1 }} className="d-flex">
              <div className={textAreaWrapperCls}>
                <div className={textAreaCls}>
                  <h2 className={headingCls}>{sectionHeading}</h2>
                  <TextBlock text={text} alignment="center" mode="muted" />
                  <div className={buttonsCls}>
                    {firstBtn?.url && (
                      <LinkButton
                        className={linkBtnStyles}
                        link={firstBtn?.url}
                        title={firstBtn?.title}
                        target={firstBtn?.target}
                        variant="outlined"
                      />
                    )}
                    {secondBtn?.url && (
                      <LinkButton
                        className={linkBtnStyles}
                        link={secondBtn?.url}
                        title={secondBtn?.title}
                        target={secondBtn?.target}
                        variant="outlined"
                      />
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
    </Section>
  )
}

export default TextAndLeftImage
